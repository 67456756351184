import axios from 'axios';

const URL_API_TIMELINE = process.env.VUE_APP_URL_API_TIMELINE;

export class TimelineApiService {
  getAllTimeline() {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_TIMELINE}getAllTimeline`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
}